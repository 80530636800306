import React from "react";
import { css } from "@emotion/react";

const buttonStyle = css`
  display: inline-block;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid #000;
  border-radius: 3px;
  color: #000;
  background: #fff;
  box-shadow: 1px 1px;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4em;
  padding: 12px 20px;
  text-align: center;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  :hover {
    color: #000;
    background: rgb(255, 219, 87);
    box-shadow: 3px 3px;
  }
  :active {
    color: #000;
    background: rgb(250, 129, 0);
  }
`;

export function Button({ children, href, ...props }) {
  const isLink = href != null;
  return isLink ? (
    <a {...props} href={href} css={{ ...props?.css, ...buttonStyle }}>
      {children}
    </a>
  ) : (
    <button {...props} css={{ ...props?.css, ...buttonStyle }}>
      {children}
    </button>
  );
}
