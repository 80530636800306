import React, { useEffect } from "react";

function handleClick(elem) {
  return e => {
    e.preventDefault();
    let range, selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(elem);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(elem);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    e.stopPropagation();
  };
}

export function SelectAllText({ selector }) {
  useEffect(() => {
    document.querySelectorAll(selector).forEach(elem => {
      if (elem && elem.innerText) {
        elem.addEventListener("click", handleClick(elem));
        // elem.addEventListener("touchstart", handleClick(elem));
      }
    });
  }, [selector]);

  return <></>;
}
