// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-devops-overview-mdx": () => import("./../../../../docs/devops/overview.mdx" /* webpackChunkName: "component---docs-devops-overview-mdx" */),
  "component---docs-introduction-business-central-mdx": () => import("./../../../../docs/introduction/business-central.mdx" /* webpackChunkName: "component---docs-introduction-business-central-mdx" */),
  "component---docs-introduction-hi-there-mdx": () => import("./../../../../docs/introduction/hi-there.mdx" /* webpackChunkName: "component---docs-introduction-hi-there-mdx" */),
  "component---docs-introduction-our-job-mdx": () => import("./../../../../docs/introduction/our-job.mdx" /* webpackChunkName: "component---docs-introduction-our-job-mdx" */),
  "component---docs-introduction-what-is-erp-mdx": () => import("./../../../../docs/introduction/what-is-erp.mdx" /* webpackChunkName: "component---docs-introduction-what-is-erp-mdx" */),
  "component---docs-my-first-extension-create-app-mdx": () => import("./../../../../docs/my-first-extension/create-app.mdx" /* webpackChunkName: "component---docs-my-first-extension-create-app-mdx" */),
  "component---docs-my-first-extension-create-sandbox-mdx": () => import("./../../../../docs/my-first-extension/create-sandbox.mdx" /* webpackChunkName: "component---docs-my-first-extension-create-sandbox-mdx" */),
  "component---docs-my-first-extension-install-tools-mdx": () => import("./../../../../docs/my-first-extension/install-tools.mdx" /* webpackChunkName: "component---docs-my-first-extension-install-tools-mdx" */),
  "component---docs-my-first-extension-overview-mdx": () => import("./../../../../docs/my-first-extension/overview.mdx" /* webpackChunkName: "component---docs-my-first-extension-overview-mdx" */),
  "component---docs-my-first-extension-publish-app-mdx": () => import("./../../../../docs/my-first-extension/publish-app.mdx" /* webpackChunkName: "component---docs-my-first-extension-publish-app-mdx" */),
  "component---docs-my-first-extension-publish-to-devops-mdx": () => import("./../../../../docs/my-first-extension/publish-to-devops.mdx" /* webpackChunkName: "component---docs-my-first-extension-publish-to-devops-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

