import React from "react";
import { css } from "@emotion/react";
import { Button } from "./Button";

const wrapperStyle = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export function Navigation({ previous, next }) {
  return (
    <div css={wrapperStyle}>
      <Button
        href={previous}
        css={{ visibility: previous != null ? "visible" : "hidden" }}
      >
        <span role="img" aria-label="Left arrow">
          ⬅️
        </span>{" "}
        Previous
      </Button>
      <Button
        href={next}
        css={{ visibility: next != null ? "visible" : "hidden" }}
      >
        Next{" "}
        <span role="img" aria-label="Right arrow">
          ➡️
        </span>
      </Button>
    </div>
  );
}
